export const convertCentsToDollars = (cents: number) => {
  const convert = cents / 100;
  return convert.toLocaleString('ru-RU');
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const countryCode = phoneNumber.slice(0, 1);
  const areaCode = phoneNumber.slice(1, 4);
  const firstPart = phoneNumber.slice(4, 7);
  const secondPart = phoneNumber.slice(7, 9);
  const thirdPart = phoneNumber.slice(9, 11);

  return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}-${thirdPart}`;
};

export const formatNumber = (cardNumber: string) => {
  const formattedNumber = cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4');
  return formattedNumber;
};
