import '../src/scss/index.scss';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';

import App from './components/common/App/App';
import { Loader } from './components/common/Loader';

import './i18n';

const rootElem = document.getElementById('root');
if (rootElem) {
  const root = ReactDOM.createRoot(rootElem);
  root.render(
    <BrowserRouter>
      <Suspense fallback={<Loader style="global_center" />}>
        <App />
      </Suspense>
    </BrowserRouter>
  );
}
