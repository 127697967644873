import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { BASE_URL, authAPI, catalog, exchangeRate, payment } from './axios';
import { Catalog, Cryptocurrency } from '../interfaces';

export const authenticate = async (
  initData: string,
  setIsLoadingAuth?: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const response = await authAPI.auth(initData);
    const pairKey = response.data.pairKey;
    localStorage.setItem('accessToken', pairKey.accessToken);
    localStorage.setItem('refreshToken', pairKey.refreshToken);
    localStorage.setItem('id', response.data.user.id);
  } catch (error) {
    console.log(error);
  } finally {
    setIsLoadingAuth?.(false);
  }
};

export const getRefreshToken = async (
  refreshToken: string | null,
  initData: string,
  setIsLoadingAuth?: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/refresh-token`, {
      token: refreshToken,
    });

    localStorage.setItem('accessToken', response.data.pairKey.accessToken);
    localStorage.setItem('refreshToken', response.data.pairKey.refreshToken);
  } catch (error) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('id');
    await authenticate(initData);
  } finally {
    setIsLoadingAuth?.(false);
  }
};

export const getAllCatalogs = async (setCatalogList: Dispatch<SetStateAction<Catalog[]>>) => {
  try {
    const response = await catalog.getCatalogs();
    const allCatalogs = response.data;
    setCatalogList(allCatalogs.catalogs);
  } catch (error) {
    console.log(error);
  }
};

export const getAllСryptocurrency = async (
  setCryptocurrencies: Dispatch<SetStateAction<Cryptocurrency[]>>
) => {
  try {
    const response = await payment.getCryptocurrencies();
    const allCryptocurrencies = response.data.currencies;
    setCryptocurrencies(allCryptocurrencies);
  } catch (error) {
    console.log(error);
  }
};

export const fetchExchangeRate = async (
  setExchangeRate: Dispatch<SetStateAction<number>>,
  setIsLoadingExchangeRate?: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const { data } = await exchangeRate.getExchangeRate();
    setExchangeRate(data.rate);
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(() => {
      setIsLoadingExchangeRate?.(false);
    }, 1500);
  }
};
