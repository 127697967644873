import { ReactComponent as Logo } from '../assets/icons/logo2.svg';
import { ReactComponent as TronBadgeLogo } from '../assets/icons/TronBadge.svg';
import { ReactComponent as BitcoinBadgeLogo } from '../assets/icons/BitcoinBadge.svg';
import { ReactComponent as MoneroBadgeLogo } from '../assets/icons/MoneroBadge.svg';
import { ReactComponent as TetherBadgeLogo } from '../assets/icons/TetherBadge.svg';

export const FooterMainPage = [
  Logo,
  BitcoinBadgeLogo,
  TronBadgeLogo,
  MoneroBadgeLogo,
  TetherBadgeLogo,
];
