import classNames from 'classnames';

import styles from './Button.module.scss';
import { ButtonProps } from './Button.props';

export const Button = ({
  onClick,
  label,
  icon,
  isDisabled,
  className,
  type,
  iconStyle,
}: ButtonProps) => {
  const buttonClassName = classNames(
    styles[type],
    className,
    icon && styles.button_icon,
    isDisabled && styles.disabled
  );
  return (
    <button disabled={isDisabled} onClick={onClick} className={buttonClassName}>
      {icon && <span className={classNames(styles.icon, iconStyle)}>{icon}</span>}
      {label}
    </button>
  );
};
