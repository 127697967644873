import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './LanguageSwither.module.scss';
import { ReactComponent as RussiaImage } from '../../../assets/icons/russia.svg';
import { ReactComponent as UnitedKingdomSvg } from '../../../assets/icons/unitedKingdom.svg';

export const LanguageSwither = () => {
  const { i18n } = useTranslation();
  const [isVisibleAllCoutnries, setIsVisibleAllCoutnries] = useState(false);

  const currentLanguage = i18n.language;
  const currentFlag = {
    ru: <RussiaImage width={32} height={32} />,
    en: <UnitedKingdomSvg width={32} height={32} />,
  };

  const [selectCountry, setSelectCountry] = useState({
    language: currentLanguage || 'ru',
    image: currentFlag[currentLanguage.split('-')[0] as keyof typeof currentFlag],
  });

  const countries = [
    {
      language: 'ru',
      image: <RussiaImage width={32} height={32} />,
      id: 0,
    },
    {
      language: 'en',
      image: <UnitedKingdomSvg width={32} height={32} />,
      id: 1,
    },
  ];

  const [countryArray, setCountryArray] = useState(() => {
    const filteredCountries = countries.sort((a, b) => {
      if (a.language === currentLanguage) {
        return -1;
      }
      if (b.language === currentLanguage) {
        return 1;
      }
      return 0;
    });
    return filteredCountries;
  });

  const handleShowAllCountries = () => {
    setIsVisibleAllCoutnries((prev) => !prev);
  };

  const handleSelectCountry = (country: any) => {
    setSelectCountry({ language: country.language, image: country.image });
    i18n.changeLanguage(country.language);
    if (country !== countries[0]) {
      const updatedCountries = [
        country,
        ...countryArray.filter((c) => c.language !== country.language),
      ];
      setCountryArray(updatedCountries);
    }
    setIsVisibleAllCoutnries(false);
  };

  // const countriesFiltered = countries.filter(
  //   (country) => country.language !== selectCountry.language
  // );
  return (
    <div className={classNames(styles.countries_container)}>
      <p style={{ cursor: 'pointer' }} onClick={handleShowAllCountries}>
        {selectCountry.image}
      </p>
      <ul
        className={classNames(
          styles.countries_list,
          isVisibleAllCoutnries && styles.show_countries_list
        )}
      >
        {countryArray.map((country) => (
          <li
            key={country.id}
            className={styles.countries_item}
            onClick={() => handleSelectCountry(country)}
          >
            {country.image}
          </li>
        ))}
      </ul>
    </div>
  );
};
