import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_PRODUCTION_URL;

const instance = axios.create({ baseURL: BASE_URL });

instance.interceptors.request.use((config) => {
  if (window.localStorage.getItem('accessToken') && config.headers) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }

  return config;
});

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.post(`${BASE_URL}/auth/refresh-token`, {
          token: window.localStorage.getItem('refreshToken'),
        });
        // @ts-ignore
        localStorage.setItem('accessToken', response.data.pairKey.accessToken);
        localStorage.setItem('refreshToken', response.data.pairKey.refreshToken);
        return instance.request(originalRequest);
      } catch (error) {
        console.log(error);
      }
    }
  }
);

export const authAPI = {
  auth(initData: string) {
    return axios.post(`${BASE_URL}/auth/login/twa`, { initData: initData });
  },
};

export const exchangeRate = {
  getExchangeRate() {
    return instance.get(`${BASE_URL}/exchange-rate?from=EUR&to=RUB`);
  },
};

export const payment = {
  payment(data: any) {
    return instance.post('/payments', { ...data });
  },
  paymentStatus(id: number) {
    return instance.get(`/payments/${id}`);
  },
  getCryptocurrencies() {
    return instance.get(`${BASE_URL}/payments/crypto/currencies`);
  },
};

export const catalog = {
  getCatalogs() {
    return instance.get(`/catalogs`);
  },
};

export const notigications = {
  createNotification() {
    return instance.post(`${BASE_URL}/actionNotifications/subscribe`, { buyed: true });
  },
};
