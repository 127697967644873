import { Route, Routes, useLocation } from 'react-router-dom';
import { useContext, useEffect, lazy, Suspense } from 'react';

import { Header } from '../Header';
import { authenticate, getRefreshToken } from '../../../api/acyncFunction';
import { AuthContext } from '../App/App';
import { Loader } from '../Loader';
import { CatalogSelection } from '../../../pages/CatalogSelection';

const CurrentCatalog = lazy(() => import('../../../pages/CurrentCatalog/CurrentCatalog'));
const Favor = lazy(() => import('../../../pages/Favor/Favor'));
const Payment = lazy(() => import('../../../pages/Payment/Payment'));
const VideoPage = lazy(() => import('../../../pages/VideoPage/VideoPage'));

export const AppRouter = () => {
  const { pathname } = useLocation();

  //@ts-ignore
  const { setIsLoadingAuth } = useContext(AuthContext);

  //@ts-ignore
  const tg = window.Telegram.WebApp;

  const initData = tg.initData;

  useEffect(() => {
    tg.expand();
    tg.ready();
  }, [tg]);

  console.log(initData);

  useEffect(() => {
    if (!window.localStorage.getItem('accessToken') || !window.localStorage.getItem('id')) {
      authenticate(initData, setIsLoadingAuth);
    } else {
      getRefreshToken(window.localStorage.getItem('refreshToken'), initData, setIsLoadingAuth);
    }
  }, [initData, setIsLoadingAuth]);

  return (
    <div className="page">
      {pathname !== '/' && <Header />}
      <Suspense fallback={<Loader style="global_center" />}>
        <Routes>
          <Route path={'/'} element={<CatalogSelection />} />
          <Route path={'/catalog/:id'} element={<CurrentCatalog />} />
          <Route path={'/payment'} element={<Payment />} />
          <Route path={'/favor'} element={<Favor />} />
          <Route path={'/video'} element={<VideoPage />} />
        </Routes>
      </Suspense>
    </div>
  );
};
