import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/ui/Button';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './CatalogSelection.module.scss';
import { CatalogList } from '../../components/common/CatalogList';
// import { Catalog } from '../../interfaces';
// import { getAllCatalogs } from '../../api/acyncFunction';
import { Loader } from '../../components/common/Loader';
import { LanguageSwither } from '../../components/common/LanguageSwither';
import { AuthContext } from '../../components/common/App/App';
import { getAllCatalogs } from '../../api/acyncFunction';
import { Catalog } from '../../interfaces';
import { FooterMainPage } from '../../commons/constans';
export const CatalogSelection = () => {
  const navigate = useNavigate();
  const [catalogList, setCatalogList] = useState<Catalog[]>([]);
  //@ts-ignore
  const { isLoadingAuth } = useContext(AuthContext);

  const { t } = useTranslation('CatalogsPage');
  useEffect(() => {
    if (!isLoadingAuth) {
      getAllCatalogs(setCatalogList);
    }
  }, [isLoadingAuth]);
  return (
    <>
      <main className={styles.main}>
        <div className={styles.header}>
          <Logo width={18} height={27} />
          <div className={styles.content}>
            <p
              style={{ maxWidth: 289 }}
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: t('header') }}
            />

            <LanguageSwither />
          </div>
        </div>

        <div className={styles.button_container}>
          <Button
            className={styles.button}
            type="filled"
            onClick={() => navigate('/payment')}
            label={t('payment_vault')}
          />
          <Button type="purple" onClick={() => navigate('/favor')} label={t('thank_you_button')} />
        </div>

        {catalogList.length ? (
          <CatalogList catalogList={catalogList} />
        ) : (
          <Loader style={styles.loader} />
        )}
      </main>
      <footer className={styles.footer}>
        <div className={styles.icons}>
          {FooterMainPage.map((Icon) => (
            <Icon />
          ))}
        </div>
        <p className={styles.footer_text}>{t('footer_desc')}</p>
        <p className={styles.footer_text_grey}>{t('footer_sub_desc')}</p>
      </footer>
    </>
  );
};
