import { createContext, useState } from 'react';

import { AppRouter } from '../AppRoutes';
//@ts-ignore
export const AuthContext = createContext();

function App() {
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(true);

  return (
    <AuthContext.Provider value={{ isLoadingAuth, setIsLoadingAuth }}>
      <AppRouter />
    </AuthContext.Provider>
  );
}

export default App;
