import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BlueArrowIcon } from '../../../assets/icons/baseArrow.svg';
import styles from './Header.module.scss';

export const Header = () => {
  const { t } = useTranslation('Utils');
  return (
    <header className={styles.header}>
      <Link className={styles.link} to={'/'}>
        <BlueArrowIcon width={10} height={10} />
        <p className={styles.text}>{t('header_button')}</p>
      </Link>
    </header>
  );
};
