import styles from './CatalogList.module.scss';
import { CatalogListItem } from '../CatalogListItem';
import { CatalogListProps } from './CatalogList.props';

export const CatalogList = ({ catalogList }: CatalogListProps) => {
  return (
    <ul className={styles.list}>
      {catalogList.map((catalog, index) => (
        <CatalogListItem
          lenght={catalogList.length}
          index={index}
          key={catalog.id}
          catalog={catalog}
        />
      ))}
    </ul>
  );
};
