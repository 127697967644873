import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './CatalogListItem.module.scss';
import { CatalogItemProps } from './CatalogListItem.props';
import { convertCentsToDollars } from '../../../commons/utils';
import i18n from '../../../i18n';

export const CatalogListItem = ({ catalog, index, lenght }: CatalogItemProps) => {
  const BASE_ANIMATION_DELAY = 0.2;
  const animationDelay = index * BASE_ANIMATION_DELAY;
  const isOddLengthAndLastIndex = lenght % 2 !== 0 && index === lenght - 1;
  console.log(catalog);

  const currentLanguage = i18n.language;

  const { t } = useTranslation('Utils');

  console.log(catalog.description);
  return (
    <li
      style={{ animationDelay: `${animationDelay}s` }}
      className={classNames(
        styles.wrapper,
        isOddLengthAndLastIndex && styles.last_child,
        styles[catalog.classname]
      )}
    >
      <Link
        className={styles.link}
        to={`/catalog/${catalog.id}`}
        state={{
          description:
            catalog?.description[currentLanguage.split('-')[0] as keyof typeof catalog.description],
          title: catalog.title[currentLanguage.split('-')[0] as keyof typeof catalog.title],
          subtitle:
            catalog.subtitle[currentLanguage.split('-')[0] as keyof typeof catalog.subtitle],
          classname: catalog.classname,
          price: catalog.price,
          id: catalog.id,
          quantity: catalog.quantity,
          tag: catalog.tag[currentLanguage as keyof typeof catalog.tag],
          isBuyed: catalog.isBuyed,
          preOrder: catalog.preOrder,
        }}
      >
        <div className={styles.header}>
          <span className={styles.quantity}>
            {catalog.preOrder ? t('soon') : `${catalog.quantity} ${t('questionnaire')}`}
          </span>

          {catalog.isBuyed && catalog.classname === 'purple' ? (
            <p className={styles.buyed}>Оплачено</p>
          ) : (
            catalog.tag[currentLanguage.split('-')[0] as keyof typeof catalog.tag] !== null && (
              <span className={styles.hit}>
                {catalog.tag[currentLanguage.split('-')[0] as keyof typeof catalog.tag]}
              </span>
            )
          )}
        </div>
        <p className={classNames(styles.name, styles.text_color)}>
          {catalog.title[currentLanguage.split('-')[0] as keyof typeof catalog.title]}
        </p>
        <p className={classNames(styles.description, styles.text_color)}>
          {catalog.subtitle[currentLanguage.split('-')[0] as keyof typeof catalog.subtitle]} <br />
          {catalog.preOrder ? t('csoon') : null}
        </p>
        <div className={styles.bottom_container}>
          {catalog.preOrder ? <p className={styles.sale}>{t('discount_paymnent')}</p> : null}
          <p className={classNames(styles.price, catalog.preOrder && styles.active)}>
            {convertCentsToDollars(catalog.price)}€
          </p>
          {catalog.isBuyed && catalog.classname !== 'purple' ? (
            <p className={styles.buyed}>Оплачено</p>
          ) : null}
        </div>
      </Link>
    </li>
  );
};
