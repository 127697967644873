import classNames from 'classnames';

import styles from './Loader.module.scss';

type LoaderProps = {
  style?: string;
};

export const Loader = ({ style }: LoaderProps) => {
  return (
    <div className={classNames(styles.loader, style)}>
      <div className={styles.lds}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
